import React from 'react'
import { useState, useEffect } from 'react';
import Avatar from './Avatar';
import Playlist from './Playlist';

export default function NodeContent({size, nodeData, nodeId, finishCallback}) {  

    const [showAvatar, setShowAvatar] = useState(true);

    useEffect(() => {
        console.log("NodeContent nodeId="+nodeId);
        //console.log("qrCode=",qrCode);

        let currentNode = getCurrentNode();
        if (currentNode!=null) {
            if (currentNode.tipoAvvio==2) {
                setShowAvatar(false);
            }
            else {
                setShowAvatar(true);
            }
        }
        
    }, [nodeId]);

    function getCurrentNode() {
        if (nodeData!=null) {
            if (nodeData.nodo!=null && nodeData.nodo.length>0) {
                return nodeData.nodo[0];
            }
            else
                if (nodeData.nodoStart!=null && nodeData.nodoStart.length>0) {
                    return nodeData.nodoStart[0];
                }
        }
        return null;
    }

    
    function handleSwitchContent(avatarActive) {
        console.log("handleSwitchContent");
        setShowAvatar(avatarActive);
    }
    
    function handleFinishPlaylist() {
        if (finishCallback!=null) {
            finishCallback();
        }
    }

    let node = getCurrentNode();
    if (nodeData!=null && node!=null) {
        //console.log("NodeContent node", node);
        if (node.tipoAvvio!=4) {
            if (nodeData.avpAvatars!=null && nodeData.avpAvatars.length>0) {
                let avatar = nodeData.avpAvatars[0];
                let loop = (nodeData.avpPlaylistFiles==null || nodeData.avpPlaylistFiles.length==0);
                let isMaskVisibile = nodeData.sfocatura!==null && nodeData.sfocatura!=="undefined" ? nodeData.sfocatura : false;
                if (nodeData.avpPlaylistFiles!==null && nodeData.avpPlaylistFiles!=="undefined") {
                    nodeData.avpPlaylistFiles.sort(
                        function( a, b ) {
                            if ( a.ordine < b.ordine ){
                              return -1;
                            }
                            if ( a.ordine > b.ordine ){
                              return 1;
                            }
                            return 0;
                          }
                    );
                }
                if (node.tipoAvvio==0 || node.tipoAvvio==1 || node.tipoAvvio==2) {
                    if (showAvatar) {
                        return (
                            <div className="avp-nodecontent"  >
                                <Avatar avatar={avatar} finishCallback={()=>handleSwitchContent(false)} loop={loop} size={size} showMask={isMaskVisibile}  />
                            </div>
                        );
                    } 
                    else {
                        return (
                            <div className="avp-nodecontent"  >
                                <Playlist items={nodeData.avpPlaylistFiles} finishCallback={handleFinishPlaylist} size={size} showMask={isMaskVisibile} />
                            </div>
                        )
                    }
                }
                else 
                if (node.tipoAvvio==3) {
                    let avatarPip = {
                        x: node.pipAvatarX!=null && node.pipAvatarX!="undefined" ? node.pipAvatarX : 0,
                        y: node.pipAvatarY!=null && node.pipAvatarY!="undefined" ? node.pipAvatarY : 0,
                        w: node.pipAvatarW!=null && node.pipAvatarW!="undefined" ? node.pipAvatarW : 100,
                        h: node.pipAvatarH!=null && node.pipAvatarH!="undefined" ? node.pipAvatarH : 100,
                        z: node.pipAvatarZ!=null && node.pipAvatarZ!="undefined" ? node.pipAvatarZ : 0,
                    };
                    let playlistPip = {
                        x: node.pipContentsX!=null && node.pipContentsX!="undefined" ? node.pipContentsX : 0,
                        y: node.pipContentsY!=null && node.pipContentsY!="undefined" ? node.pipContentsY : 0,
                        w: node.pipContentsW!=null && node.pipContentsW!="undefined" ? node.pipContentsW : 100,
                        h: node.pipContentsH!=null && node.pipContentsH!="undefined" ? node.pipContentsH : 100,
                        z: node.pipContentsZ!=null && node.pipContentsZ!="undefined" ? node.pipContentsZ : 1,
                    };
                    return (
                        <div className="avp-nodecontent"  >
                            <Avatar avatar={avatar} loop={loop} size={size} pip={avatarPip} showMask={isMaskVisibile} />
                            <Playlist items={nodeData.avpPlaylistFiles} size={size} pip={playlistPip} finishCallback={handleFinishPlaylist} showMask={isMaskVisibile} />
                        </div>
                        
                    );
                }
            }
            else
                console.warn("NodeContent NO AVATAR");
        }
        else {
            //Esegue solo chiamata API
            console.log("NodeContent -> only API");
        }
    }
    else {
        console.error("node is null");
    }


    return (
        <div className="avp-nodecontent"  ></div>
    );

}
