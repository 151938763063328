import React from 'react'
import { useState, useEffect } from 'react';
import PlaylistItem from './PlaylistItem';

export default function Playlist({ items, size, finishCallback, pip, showMask }) {


    let sizeW = size.width;
    let sizeH = size.height;
    let margH = 0;
    let margV = 0;

    if (size.width !== size.height) {
        if (sizeW > sizeH) {
            margH = (sizeW - sizeH) / 2;
            sizeW = sizeH;
        }
        else {
            margV = (sizeH - sizeW) / 2;
            sizeH = sizeW;
        }
    }

    const pSize = {
        width: sizeH,
        height: sizeW
    };
    //console.log("PLAYLIST SIZE", pSize); 

    let currentItem = null;
    const [currentIndex, setCurrentIndex] = useState(0);
    const [currentPlaylist, setCurrentPlaylist] = useState(null);

    if (currentPlaylist == null) {
        setCurrentIndex(0);
        setCurrentPlaylist(items);
    }
    else {
        if (items != null) {
            if (currentPlaylist.length !== items.length) {
                setCurrentIndex(0);
                setCurrentPlaylist(items);
            }
            else {
                if (currentPlaylist.length > 0 && items.length > 0 && currentPlaylist[0].idPlaylist !== items[0].idPlaylist) {
                    setCurrentIndex(0);
                    setCurrentPlaylist(items);
                }
            }
        }
    }

    if (items != null && items.length > 0 && currentIndex >= 0) {
        currentItem = items[currentIndex];
        console.log("Playlist currentItem: " + (currentIndex + 1) + " of " + items.length, currentItem);
    }
    else {
        console.log("Playlist no items");
    }


    function nextItem() {
        console.log("nextItem");
        if (items !== null) {
            if (currentIndex < (items.length - 1)) {
                let newIndex = currentIndex + 1;
                console.log("set new index=" + newIndex);
                setCurrentIndex(newIndex);
            }
            else {
                console.log("PLAYLIST COMPLETED");
            }

        }
    }

    function finishItemCallback(item) {
        console.log("finishItemCallback");

        if (item.tipoContenuto == 6) {
            if (finishCallback != null) {
                finishCallback();
            }
        }
        else {
            if (item.restartPlaylist) {
                console.log("RESTART PLAYLIST set new index=0");
                setCurrentIndex(0);
            }
            else {
                nextItem();
            }
        }



    }




    const pipStyle = {};

    if (pip !== null && (typeof pip !== "undefined")) {
        //console.log("pip", pip);
        pipStyle.position = "absolute";
        pipStyle.width = pip.w + "%";
        pipStyle.height = pip.h + "%";
        pipStyle.left = pip.x + "%";
        pipStyle.top = pip.y + "%";
        pipStyle.zIndex = pip.z;
        pipStyle.transform = "none";
    }

    let isMaskVisibile = showMask;
    console.log("PLAYLIST isMaskVisibile="+showMask);

    if (currentItem != null) {
        return (
            <div className="avp-playlist" style={pipStyle} >
                <PlaylistItem item={currentItem} finishCallback={finishItemCallback} showMask={isMaskVisibile} />
            </div>
        );
    }
    else {

        return (
            <div className="avp-playlist" style={pipStyle} >
            </div>
        );
    }


}   
