// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#avp-interaction {
  margin-left: 25%;
  margin-right: 25%;
  overflow-y: hidden;
  height: 90%;
  -ms-overflow-style: none;
}

#avp-interaction:hover {
  overflow-y: scroll;
}

#avp-interaction-main {
  overflow: hidden;
}

.avp-interaction-button {
  margin-top: 2vh;
  width: 100%;
  height: 120px;
  position: relative;
  cursor: pointer;
}

.avp-interaction-button-bkg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}

.avp-interaction-button-icon {
  position: absolute;
  top: 0%;
  left: 0%;
  width: 20%;
  height: 98%;
  z-index: 2;
}

.avp-interaction-button-icon > img {
  width: 55%;
  height: auto;
  top: 17%;
  position: absolute;
  left: 28%;
}

.avp-interaction-button-text {
  position: absolute;
  top: 0;
  left: 22%;
  width: 73%;
  height: 90%;
  z-index: 2;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  justify-content: left;
  align-items: center;
}

`, "",{"version":3,"sources":["webpack://./src/interaction.css"],"names":[],"mappings":";AACA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,kBAAkB;EAClB,WAAW;EACX,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,WAAW;EACX,aAAa;EACb,kBAAkB;EAClB,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,OAAO;EACP,QAAQ;EACR,UAAU;EACV,WAAW;EACX,UAAU;AACZ;;AAEA;EACE,UAAU;EACV,YAAY;EACZ,QAAQ;EACR,kBAAkB;EAClB,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,SAAS;EACT,UAAU;EACV,WAAW;EACX,UAAU;EACV,eAAe;EACf,gBAAgB;EAChB,aAAa;EACb,qBAAqB;EACrB,mBAAmB;AACrB","sourcesContent":["\n#avp-interaction {\n  margin-left: 25%;\n  margin-right: 25%;\n  overflow-y: hidden;\n  height: 90%;\n  -ms-overflow-style: none;\n}\n\n#avp-interaction:hover {\n  overflow-y: scroll;\n}\n\n#avp-interaction-main {\n  overflow: hidden;\n}\n\n.avp-interaction-button {\n  margin-top: 2vh;\n  width: 100%;\n  height: 120px;\n  position: relative;\n  cursor: pointer;\n}\n\n.avp-interaction-button-bkg {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: 1;\n}\n\n.avp-interaction-button-icon {\n  position: absolute;\n  top: 0%;\n  left: 0%;\n  width: 20%;\n  height: 98%;\n  z-index: 2;\n}\n\n.avp-interaction-button-icon > img {\n  width: 55%;\n  height: auto;\n  top: 17%;\n  position: absolute;\n  left: 28%;\n}\n\n.avp-interaction-button-text {\n  position: absolute;\n  top: 0;\n  left: 22%;\n  width: 73%;\n  height: 90%;\n  z-index: 2;\n  font-size: 24px;\n  font-weight: 600;\n  display: flex;\n  justify-content: left;\n  align-items: center;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
