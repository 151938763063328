import React from 'react'
import { useState } from 'react';
import ReactPlayer from 'react-player'
import { JitsiMeeting } from '@jitsi/react-sdk';

export default function Avatar({avatar, loop, finishCallback, size, pip, showMask}) {  

    const playerRef = React.useRef();
    const iframeRef = React.useRef();
    
    let sizeW = size.width;
    let sizeH = size.height;
    let margH = 0;
    let margV = 0;
    if (size.width!=size.height) {
      if (sizeW>sizeH) {
        margH = (sizeW-sizeH)/2;
        sizeW = sizeH; 
      }
      else {
        margV = (sizeH-sizeW)/2;
        sizeH = sizeW;
      }
    }
    const pSize  = {
      width: sizeH,
      height: sizeW
    };  
    //console.log("AVATAR SIZE", pSize);

    const iFrameStyle = {
      position: 'relative',
      width: sizeW,
      height: sizeH,
      left: 0,
      top: 0,
      border: 0,
    };

    function handleTempoScaduto() {
        if (finishCallback!=null) {
            finishCallback(avatar);
        }
        else {
          console.log("no finishCallback for avatar");
        }
    }

    function handleIFrameLoad() {
      console.log("handleIFrameLoad", iframeRef);
      const iframeDoc = iframeRef.current?.contentWindow;
      console.log("iframeDoc", iframeDoc);
      if (iframeDoc!=null) {
        /*
        let cssStyle = iframeDoc.document.createElement("style");
        cssStyle.innerHTML = "head { display:none !important;} footer { display: none !important;}";
        iframeDoc.document.head.appendChild(cssStyle);  
        */
      }
      else {
        console.error("no iframe doc");
      }
   
      
    }

    function handleIFrame3Load() {
      console.log("handleIFrame3Load", iframeRef);
      const iframeDoc = iframeRef.current?.contentWindow;
      console.log(iframeDoc);
      if (iframeDoc!=null) {

        let script = iframeDoc.document.createElement("script");
        script.innerHTML = "setTimeout( function() { alert(\"ciao\"); }, 2000 );";
        iframeDoc.document.head.appendChild(script);  
      }
      else {
        console.error("no iframe doc");
      }
   
      
    }

    const pipStyle = {};

    if (pip!==null && (typeof pip!=="undefined")) {
        //console.log("pip", pip);
        pipStyle.position = "absolute";
        pipStyle.width = pip.w+"%";
        pipStyle.height = pip.h+"%";
        pipStyle.left = pip.x+"%";
        pipStyle.top = pip.y+"%";
        pipStyle.zIndex = pip.z;
        pipStyle.transform = "none";
    }

    

    if (avatar!=null) {
        if (avatar.tipoContenuto==0 || avatar.tipoContenuto==2) {
            return (
                <div id="avp-avatar"  style={pipStyle} >
                  <div id="avp-avatar-container"  >
                    <img src={avatar.pathFile}  />
                  </div>
                  <div id="avp-avatar-mask" style={{ display: showMask ? "block" : "none"}} >
                    <img src="/images/campo_sfocatura.png" />
                  </div>
                </div>              
            );
        }
        else
        if (avatar.tipoContenuto==5 || avatar.tipoContenuto==1) {
            return (
                <div id="avp-avatar"   style={pipStyle} >
                  <div id="avp-avatar-container"  >
                  <ReactPlayer
                      ref={playerRef}
                      url={avatar.pathFile}
                      playing={true}
                      width="100%"
                      height="100%"
                      loop={loop}
                    
                      onEnded={()=>handleTempoScaduto()}
                    />

                  </div>
                  <div id="avp-avatar-mask" style={{ display: showMask ? "block" : "none"}} >
                    <img src="/images/campo_sfocatura.png" />
                  </div>
                </div>                
            );
        }
        else
        if (avatar.tipoContenuto==3) {
          let settingsJson = { 
            domain: "",
            roomName: "",
            displayName: "",
          };
          try {
            settingsJson = JSON.parse(avatar.settings);
          }
          catch(ex) {
            console.warn("no json value in settings field");
          }
          
          return (
              <div id="avp-avatar"  style={pipStyle} >
                <JitsiMeeting
                    domain = {settingsJson!==null && settingsJson!=="undefined" ? settingsJson.domain : ""}
                    roomName = {settingsJson!==null && settingsJson!=="undefined" ? settingsJson.roomName : ""}
                    configOverwrite = {{
                        startWithAudioMuted: false,
                        startWithVideoMuted: false,
                        disableModeratorIndicator: true,
                        startScreenSharing: true,
                        enableEmailInStats: false,
                        prejoinConfig: {
                          enabled: false
                        }
                    }}
                    interfaceConfigOverwrite = {{
                      TOOLBAR_BUTTONS: [
                        'microphone', 'camera', 'hangup', 'chat', 'settings'
                        // Aggiungi i pulsanti che vuoi visualizzare nella toolbar
                      ]
                    }}
                    
                    userInfo = {{
                        displayName: settingsJson!==null && settingsJson!=="undefined" ? settingsJson.displayName : ""
                    }}
                    onApiReady = { (externalApi) => {
                        // here you can attach custom event listeners to the Jitsi Meet External API
                        // you can also store it locally to execute commands
                    } }
                    getIFrameRef = { (iframeRef) => { iframeRef.style.height = "100%"; iframeRef.style.width = "100%"; } }
                />
                <div id="avp-avatar-mask" style={{ display: showMask ? "block" : "none"}} >
                  <img src="/images/campo_sfocatura.png" />
                </div>                
              </div>              
          );
        }        
        else
        if (avatar.tipoContenuto==4) {
          return (
              <div id="avp-avatar"  style={pipStyle}  >
                  <iframe style={iFrameStyle} allow="microphone" src={avatar.pathFile} onLoad={()=>handleIFrameLoad()} ref={iframeRef} />
              </div>              
          );
      }        
    }

    return (
        <div class="avp-avatar"  style={pipStyle} ></div>
    );


  
  }      


