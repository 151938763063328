import React from 'react'
import { useState, useRef } from 'react';
import ReactPlayer from 'react-player'
import { JitsiMeeting } from '@jitsi/react-sdk';

export default function PlaylistItem({ item, finishCallback, showMask }) {

    const timeOut = useRef(null);
    const iframeRef = React.useRef();
    const [currentItem, setCurrentItem] = useState(0);

    const iFrameStyle = {
        position: 'relative',
        width: '100%',
        height: '100%',
        left: 0,
        top: 0,
        border: 0,
    };


    function handleTempoScaduto() {
        console.log("handleTempoScaduto");
        if (finishCallback != null) {
            finishCallback(item);
        }
    }

    function handleIFrameLoad() {
        console.log("handleIFrameLoad", iframeRef);
        const iframeDoc = iframeRef.current?.contentWindow;
        console.log("iframeDoc", iframeDoc);
        if (iframeDoc != null) {
            //const anchors = iframeDoc.contentWindow.document.getElementsByTagName("a");
            /*
            const connectButton = iframeDoc.contentWindow.document.querySelector('[data-testid="prejoin.joinMeeting"]');
                
            if (connectButton) {
                connectButton.click(); // Simula il click su "Collegati alla riunione"
                console.log("Collegati alla riunione cliccato.");
            } else {
            }
            */
        }
        else {
            console.error("no iframe doc");
        }


    }

    if (item != null) {

        if (item.tipoContenuto == 6) {
            console.log("exit playlist item ");
            handleTempoScaduto();
        }

        if (currentItem == null || (currentItem !== null && item.idPlaylistFile !== currentItem.idPlaylistFile)) {
            setCurrentItem(item);
            if (item.loop == false) {
                if (timeOut != null) {
                    clearTimeout(timeOut.current);
                    if (item.durataVisualizzazione == 0) {
                        if (item.tipoContenuto == 2) {
                            //image
                            handleTempoScaduto();
                        }
                        else {
                            //video
                            //durata video
                        }
                    }
                    else {
                        console.log("set new timeout to seconds", item.durataVisualizzazione);
                        timeOut.current = setTimeout(() => {
                            handleTempoScaduto();
                        }, item.durataVisualizzazione * 1000);
                    }
                }
            }
        }

        if (item.tipoContenuto == 2) {

            return (
                <div className="avp-playlist-container">
                    <div className="avp-playlist-item" id={item.codiceFile}   >
                        <img src={item.pathFile} />
                    </div>
                    <div className="avp-playlist-mask" style={{ display: showMask ? "block" : "none"}} >
                        <img src="/images/campo_sfocatura.png" />
                    </div>   
                </div>

            );
        }
        else
            if (item.tipoContenuto == 1 || item.tipoContenuto == 5) {
                return (
                    <div className="avp-playlist-container">
                        <div className="avp-playlist-item"   >
                            <ReactPlayer
                                url={item.pathFile}
                                playing={true}
                                width="100%"
                                height="100%"
                                loop={item.loop}
                                onEnded={() => handleTempoScaduto()}
                            />
                        </div>
                        <div className="avp-playlist-mask" style={{ display: showMask ? "block" : "none"}} >
                            <img src="/images/campo_sfocatura.png" />
                        </div>   
                    </div>

                );
            }
            else
                if (item.tipoContenuto == 3) {
                    let settingsJson = {
                        domain: "",
                        roomName: "",
                        displayName: "",
                    };
                    try {
                        settingsJson = JSON.parse(item.settings);
                    }
                    catch (ex) {
                        console.warn("no json value in settings field");
                    }

                    return (
                        <div className="avp-playlist-item" >
                            <JitsiMeeting
                                domain={settingsJson !== null && settingsJson !== "undefined" ? settingsJson.domain : ""}
                                roomName={settingsJson !== null && settingsJson !== "undefined" ? settingsJson.roomName : ""}
                                configOverwrite={{
                                    prejoinPageEnabled: false,  // Disabilita la schermata di pre-partecipazione
                                    startWithAudioMuted: false, // Microfono attivo all'inizio
                                    startWithVideoMuted: false,  // Video attivo all'inizio
                                    disableModeratorIndicator: true,
                                    startScreenSharing: true,
                                    enableEmailInStats: false
                                }}
                                interfaceConfigOverwrite={{
                                    DISABLE_JOIN_LEAVE_NOTIFICATIONS: true,
                                    SHOW_JITSI_WATERMARK: false,
                                    TOOLBAR_BUTTONS: [
                                        'microphone', 'camera', 'hangup', 'chat', 'settings'
                                        // Aggiungi i pulsanti che vuoi visualizzare nella toolbar
                                    ]
                                }}
                                userInfo={{
                                    displayName: settingsJson !== null && settingsJson !== "undefined" ? settingsJson.displayName : ""
                                }}
                                onApiReady={(externalApi) => {
                                    // Forza il video con un timeout per assicurarsi che la conferenza sia pronta
                                    setTimeout(() => {
                                        externalApi.executeCommand('toggleVideo');
                                        console.log("Forzato video dopo ritardo.");
                                    }, 5000);
                                }}
                                getIFrameRef={(iframeRef) => { iframeRef.style.height = "100%"; iframeRef.style.width = "100%"; }}
                            />
                        </div>
                    );
                }
                else
                    if (item.tipoContenuto == 4) {
                        return (
                            <div className="avp-playlist-item"  >
                                <iframe style={iFrameStyle} allow="microphone" src={item.pathFile} onLoad={() => handleIFrameLoad()} ref={iframeRef} />
                            </div>
                        );
                    }
    }

    return (
        <div className="avp-playlist-item"  ></div>
    );

}   
