// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.avp-playlist{
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translate(-50%, -50%);
  left: 50%;
  z-index: 2;
  overflow: hidden;
  -ms-overflow-style: none;
}

.avp-playlist-container {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 3;
  overflow: hidden;
  -ms-overflow-style: none;
}

.avp-playlist-item {
  overflow: hidden;
    width: 100%;
    height: 100%;
    -ms-overflow-style: none;  
}

.avp-playlist-item img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.avp-playlist-mask {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 4;
}

.avp-playlist-mask > img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}`, "",{"version":3,"sources":["webpack://./src/playlist.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,QAAQ;EACR,gCAAgC;EAChC,SAAS;EACT,UAAU;EACV,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,UAAU;EACV,gBAAgB;EAChB,wBAAwB;AAC1B;;AAEA;EACE,gBAAgB;IACd,WAAW;IACX,YAAY;IACZ,wBAAwB;AAC5B;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO;EACP,UAAU;AACZ;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":["\n.avp-playlist{\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 50%;\n  transform: translate(-50%, -50%);\n  left: 50%;\n  z-index: 2;\n  overflow: hidden;\n  -ms-overflow-style: none;\n}\n\n.avp-playlist-container {\n  position: relative;\n  width: 100%;\n  height: 100%;\n  z-index: 3;\n  overflow: hidden;\n  -ms-overflow-style: none;\n}\n\n.avp-playlist-item {\n  overflow: hidden;\n    width: 100%;\n    height: 100%;\n    -ms-overflow-style: none;  \n}\n\n.avp-playlist-item img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}\n\n.avp-playlist-mask {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  z-index: 4;\n}\n\n.avp-playlist-mask > img {\n  width: 100%;\n  height: 100%;\n  object-fit: contain;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
