import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom'  
import { useState, useEffect } from 'react';
import OloScreen from './components/OloScreen'
import QRCodeScreen from './components/QRCodeScreen'
import TouchScreen from './components/TouchScreen'
import InteractionScreen from './components/InteractionScreen'
import CheckPinScreen from './components/CheckPinScreen';
import Notfound from './notfound' 
import { getAppType, getValueByQueryString } from "./components/manager/util";
import { getAuthData, getAvp, getIDUtente, getLingua } from "./components/manager/session_config";
import { apiAuthenticate, apiClientGetSessionStatusById, apiStartAVP, apiStartSession, apiGoToNextNode, apiTerminateSession, apiCheckPin } from "./components/manager/api_manager";
import { useEffectOnce } from './components/manager/use_effect_once';
//import WindowManager from './components/manager/WindowManager.tsx';
import axios from 'axios';
import './App.css';

function App() {

  const [count, setCount] = useState(0);



  let screenType = -1; //0=touch, 1=OLO, 2=qrcode, 3=iteraction
  let canCheckSession = false;
  let sessionId = null;
  let pollingInt = null;
  let lastCheckSessionDate = null;
  let currentArc = null;
  let currentIdLingua = 1;


  //console.log("App");
  let client = null;
  let token = null;
  let apiAuthPostCallback = null;
  //const [client, setClient] = useState(null);
  const [currentNode, setCurrentNode] = useState(null);
  const [currentQRCode, setCurrentQRCode] = useState(null);
  //const [token, setToken] = useState(null);
  const wSize = useWindowSize();

  useEffectOnce(() => {
    startAPP();
    
    // do something here!
    return () => stopAPP();
  });


  useEffect(() => {
      let interval = null;
      const screenT = getAppType();
      
      if (screenT!=4) {
          interval = setInterval(() => {
            //console.log("INTERVAL");
            executePolling();
          }, 2000);
      }
      else {
        executePolling();
      } 

      //Clearing the interval
      return () => clearInterval(interval);
  }, []);


  return (
    <Router>  
      <Routes>  
        <Route path="/avp/olo" element={<OloScreen size={wSize} node={currentNode} restartCallback={handleRestart} nodeId={(currentNode!=null && currentNode.nodo!=null && currentNode.nodo.length>0) ? currentNode.nodo[0].idNodo : -1} />} />  
        <Route path="/avp/touch" element={<TouchScreen size={wSize} node={currentNode} qrCode={currentQRCode} clickCallback={handleButton} restartCallback={handleRestart} nodeId={(currentNode!=null && currentNode.nodo!=null && currentNode.nodo.length>0) ? currentNode.nodo[0].idNodo : -1} />}  />  
        <Route path="/avp/interaction" element={<InteractionScreen size={wSize} node={currentNode} clickCallback={handleButton} restartCallback={handleRestart} />} />  
        <Route path="/avp/qrcode" element={<QRCodeScreen size={wSize} node={currentNode} qrCode={currentQRCode} nodeId={(currentNode!=null && currentNode.nodo!=null && currentNode.nodo.length>0) ? currentNode.nodo[0].idNodo : -1} />} />  
        <Route path="/avp/checkpin" element={<CheckPinScreen size={wSize} node={currentNode} pinCallback={handleCheckPIN}  />} />  
        <Route component={Notfound} />  
      </Routes>  
    </Router> 
  );

  //API CALLBACK
  function apiAuthCallback(response) {
    console.log("apiAuthCallback:", response);
    if (response!=null && response.status>=200 && response.status<400) {
      token = response.data.token;
      canCheckSession = true;
      startPolling();
      if (apiAuthPostCallback!=null) {
        apiAuthPostCallback();
      }
      else
        console.log("apiAuthPostCallback is null");
    }
    else
        console.error("apiAuthCallback");
  }

  function apiClientGetSessionStatusByIdCallback(response) {
    console.log("apiSessionCallback:", response);
    if (response!=null && response.status>=200 && response.status<400) {
      if (response.data.sessioneAttiva) {
        if (currentNode!=null) {
          let curNodData = null;
          let tmpNodData = null;
          if (currentNode.node!=null && currentNode.node.length>0) {
            curNodData = currentNode.data.node[0];
          }
          if (response.data.node!=null && response.data.node.length>0) {
            tmpNodData = response.data.node[0];
          }  
          if (tmpNodData!=null && curNodData!=null && curNodData.idNodo==tmpNodData.idNodo) {
            //same
          }
          else {
            console.log("different node");
            setCurrentNode(response.data);
          }       

        }
        else {
          setCurrentNode(response.data);
        }
      }
      else {
        console.log("SESSIONE NON ATTIVA -> api StartAVP");
        canCheckSession = false;
        apiStartAVP(client, token, getIDUtente(), getAvp(), apiStartAVPCallback );
      }
    }
    else {
      console.log("RISPOSTA NON VALIDA -> SESSIONE NON ATTIVA -> api StartAVP");
      canCheckSession = false;
      apiStartAVP(client, token, getIDUtente(), getAvp(), apiStartAVPCallback );
    }
  } 
  
  function apiStartAVPCallback(response) {
    console.log("startAVPCallback:", response);
    if (response!=null && response.status>=200 && response.status<400) {
      canCheckSession = true;
      console.log("NODE START");
      setCurrentNode(response.data);
      if (response.data.qrCode && response.data.qrCodeJson!=null && response.data.qrCodeJson.length>0) {
        const qrCode = {
          json: response.data.qrCodeJson,
          text: response.data.qrCodeText,
          preset: response.data.qrCodePreset,
          styleClass: response.data.qrCodeStyleClass,
          align: response.data.qrCodeAllineamento
        };
        setCurrentQRCode(qrCode);
      }
      else {
        setCurrentQRCode(null);
      }
    }
    else {

    }
  }

  function apiStartSessionCallback(response) {
    console.log("apiStartSessionCallback", response);
    if (response!=null && response.status>=200 && response.status<400) {
      setCurrentNode(response.data);
    }
    else {
      
    }
  }

  function apiTerminateSessionCallback(response) {
    console.log("apiTerminateSessionCallback", response);
    if (response!=null && response.status>=200 && response.status<400) {
      apiStartAVP(client, token, getIDUtente(), getAvp(), apiStartAVPCallback );
    }
    else {
      
    }
  }  

  function apiGoToNextNodeCallback(response) {
    console.log("apiGoToNextNodeCallback", response);
    if (response!=null && response.status>=200 && response.status<400) {
      setCurrentNode(response.data);
    }
    else {
      
    }
  }

  function initAPIClient(callback) {
    if (client==null) {
      client = axios.create({
        baseURL: "https://avswebapi.bitreloaded.it/api" 
      });
      console.log("axios client created");

      apiAuthPostCallback = callback;
      //AUTHENTICATE
      apiAuthenticate(client, getAuthData(), apiAuthCallback);
    }
    else {
      if (callback!=null) {
        callback();
      }
    }
   
  }

  //START APP
  function startAPP() {
    screenType = getAppType();
    console.log('startAPP screen type = '+screenType);
    
    initAPIClient(null);

  }

  //STOP APP
  function stopAPP() {
    console.log('stopAPP');
    stopPolling();
  }

  //POLLING
  function startPolling() {
    /*
    console.log('POLLING STARTED');
    if (pollingInt!=null) {
      stopPolling();
    }
    pollingInt = setInterval( executePolling, 5000);
    */
  }

  function executePolling() {
    console.log('EXECUTE POLLING');

      //CHECK SESSION
      if (canCheckSession) {
        apiClientGetSessionStatusById(client, token, getIDUtente(), getAvp(), apiClientGetSessionStatusByIdCallback )
      }
      else 
        console.log("canCheckSession = false");
  }  

  function stopPolling() {
    /*
    console.log('POLLING STOPPED');
    clearInterval(pollingInt);
    pollingInt = null;
    */
  }

  //INTERACTION CALLBACKS

  function performAction() {
    if (currentNode.nodoStart) {
      let qrToken = getValueByQueryString("qrtoken");
      apiStartSession(client, token, getIDUtente(), getAvp(), currentIdLingua, qrToken, apiStartSessionCallback );
    }
    else {
      if (currentArc!=null) {
        apiGoToNextNode(client, token, currentNode.codSessione, currentArc.idArco, apiGoToNextNodeCallback )
      }
      else {
        console.error("currentArc is null")
      }
    }
  }

  function performRestart() {
    apiTerminateSession(client, token, getIDUtente(), getAvp(), apiTerminateSessionCallback );
  } 

  function handleButton(arc) {
    console.log("handleButton...");
    console.log("arc", arc);
    if (arc!=null) {
      currentArc = arc;
      if (currentNode!=null) {
        if (currentNode.nodoStart) {
          console.log("currentNode", currentNode);
          currentIdLingua = currentArc.idLingua;
        }

        stopPolling();
        initAPIClient(performAction);
      }
    }
  }

  function handleRestart() {
    console.log("handleRestart...");

    initAPIClient(performRestart);
  }

  function handleCheckPIN(pin) {
    console.log("handleCheckPIN..."+pin);

    initAPIClient( function() {
      apiCheckPin(client, token, getIDUtente(), getAvp(), pin, apiCheckPINCallbak );
    });

    

  }

  function apiCheckPINCallbak(response) {
    console.log(response);
    if (response!=null && response.status>=200 && response.status<400) {
      const idUtente = getIDUtente();
      const idAvp = getAvp();
      window.location.href = "/avp/interaction/?idUtente="+idUtente+"&idAvp="+idAvp;
    }
    else {
      console.log("PIN NOT VALID");
    }
  }  

  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    const [windowSize, setWindowSize] = useState({
      width: 0,
      height: 0,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
        
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    
    return windowSize;
  }

}



export default App;
