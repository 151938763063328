import React from 'react'
import { useState, useEffect } from 'react';
import Interaction from './Interaction';
import NodeContent from './NodeContent';
import { calculateContainerSize } from "./manager/util";
import QRCode from './QRCode';

export default function TouchScreen({size, node, nodeId, clickCallback, restartCallback, qrCode}) {  

  useEffect(() => {
    //console.log("TouchScreen nodeId="+nodeId);

    
  }, [nodeId]);

  const cSize = calculateContainerSize(size, 16/9);
  const pSize  = {
    width: (cSize.width / 2),
    height: cSize.height
  };

  const containerStyle = {
    width: cSize.width,
    height: cSize.height,
    left: cSize.leftMargin,
    top: cSize.topMargin
  };

  function handleRestart() {
    console.log("handleRestart");
    if (restartCallback!=null) {
      restartCallback();
    }
  }  

  if (node!=null) {
    //console.log("TouchScreen > node ok");
    return (
      <div id="avp-touch-screen" style={containerStyle} >
        <div id="avp-touch-screen-body">
          <img id="avp-touch-screen-body-bkg" src="/images/bkg_avatar_sx.png" />
          <div id="avp-touch-main">
            <div id="avp-touch-main-left">
              <NodeContent nodeData={node} nodeId={nodeId} size={pSize} finishCallback={handleRestart} />
            </div>
            <div id="avp-touch-main-right">
              {
                qrCode!=null ? 
                (<QRCode size={size} qrCode={qrCode} />)
                :
                (<Interaction size={size} nodeData={node} clickCallback={clickCallback} />)
              }
            </div>          
          </div>
          <div id="avp-touch-companylogo">
            <img src="/images/company_logo.png" />
          </div>
          <div id="avp-touch-restart" onClick={()=>handleRestart()} >
            <img src="/images/restart_button.png" />
          </div>            
        </div>
      </div>
    ); 

  }
  else {
    console.error("node is null");
  }  

  return (
    <div id="avp-touch-screen" style={containerStyle} >
      <div id="avp-touch-screen-body">
        <img id="avp-touch-screen-body-bkg" src="/images/bkg_avatar_sx.png" />
        <div id="avp-touch-main">
        </div>
        <div id="avp-touch-companylogo">
          <img src="/images/company_logo.png" />
        </div>
        <div id="avp-touch-restart" onClick={()=>handleRestart()} >
          <img src="/images/restart_button.png" />
        </div>            
      </div>
    </div>
  ); 

}  
 